import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { columnPush } from "utils";
import {
  H1,
  Image,
  PageHeader,
  Section,
  SEO,
  SplitImage,
  RichText,
  FadeGroup,
  FadeInGroup,
} from "components";

import Grid from "./_grid";

import { serializer, linkResolver } from "utils";

const CommunityPage = ({ preview }) => (
  <StaticQuery
    query={graphql`
      {
        prismicCommunity {
          dataString
          data {
            main_image {
              fluid(maxWidth: 2048) {
                ...GatsbyPrismicImageFluid
              }
            }
            collage_image_one {
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            collage_image_two {
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            collage_image_three {
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            main_feature_image {
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            feature_one_image {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            feature_two_image {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            feature_three_image {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            feature_four_image {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            footer_image {
              fluid(maxWidth: 2048) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.prismicCommunity.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={page.seo_image && page.seo_image.url}
            title={page.seo_title && PrismicRichText.asText(page.seo_title)}
            description={
              page.seo_description &&
              PrismicRichText.asText(page.seo_description)
            }
          />
          <PageHeader
            bg="brand.nickel"
            src={preview ? page.main_image && page.main_image.url : undefined}
            fluid={
              preview
                ? undefined
                : data.prismicCommunity.data.main_image &&
                  data.prismicCommunity.data.main_image.fluid
            }
            alt={page.main_image && page.main_image.alt}
          />
          <Section pt={2} pb={3} bg={["brand.navy", "bg.default"]}>
            <div
              css={`
                -ms-grid-column: 3;
                -ms-grid-column-span: 11;
                grid-column: 2 / 8;
                -ms-grid-row: 1;
                grid-row: 1;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 15;
                  grid-column: 1 / 8;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                }
              `}
            >
              <div
                css={`
                  position: relative;
                  ${columnPush(1)};
                  ${(props) => props.theme.mediaQueries.small} {
                    padding-left: 0;
                  }
                `}
              >
                <FadeGroup>
                  <H1
                    children={page.title && PrismicRichText.asText(page.title)}
                    mb="0.5em"
                    color={["text.reverse", "text.default"]}
                  />
                  <RichText
                    children={
                      page.description &&
                      PrismicRichText.render(
                        page.description,
                        linkResolver,
                        serializer
                      )
                    }
                    color={["text.reverse", "text.default"]}
                  />
                </FadeGroup>
              </div>
            </div>
          </Section>
          <Section
            pb={3}
            css={`
              ${(props) => props.theme.mediaQueries.small} {
                padding-top: 5vw;
                padding-bottom: 5vw;
              }
            `}
          >
            <div
              css={`
                display: flex;
                align-items: center;
                -ms-grid-column: 1;
                -ms-grid-column-span: 11;
                grid-column: 1 / 7;
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                grid-row: 1 / 3;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 15;
                  grid-column: 1 / 9;
                  -ms-grid-row: 1;
                  -ms-grid-row-span: 1;
                  grid-row: 1;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                  margin: 0 -5vw;
                }
              `}
            >
              <FadeInGroup style={{ width: "100%" }}>
                <Image
                  src={
                    preview
                      ? page.collage_image_one && page.collage_image_one.url
                      : undefined
                  }
                  fluid={
                    preview
                      ? undefined
                      : data.prismicCommunity.data.collage_image_one &&
                        data.prismicCommunity.data.collage_image_one.fluid
                  }
                  alt={page.collage_image_one && page.collage_image_one.alt}
                  style={{ width: "100%" }}
                />
              </FadeInGroup>
            </div>
            <div
              css={`
                -ms-grid-column: 13;
                -ms-grid-column-span: 9;
                grid-column: 7 / 12;
                -ms-grid-row: 1;
                grid-row: 1;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                  -ms-grid-row: 2;
                  grid-row: 2;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                  margin: -10vw -5vw 0;
                }
                ${(props) =>
                  props.theme.mediaQueries
                    .medium} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                  margin-top: 5vw;
                }
              `}
            >
              <FadeInGroup>
                <Image
                  src={
                    preview
                      ? page.collage_image_two && page.collage_image_two.url
                      : undefined
                  }
                  fluid={
                    preview
                      ? undefined
                      : data.prismicCommunity.data.collage_image_two &&
                        data.prismicCommunity.data.collage_image_two.fluid
                  }
                  alt={page.collage_image_two && page.collage_image_two.alt}
                />
              </FadeInGroup>
            </div>
            <div
              css={`
                -ms-grid-column: 15;
                -ms-grid-column-span: 9;
                grid-column: 8 / 13;
                -ms-grid-row: 2;
                grid-row: 2;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 9;
                  -ms-grid-column-span: 7;
                  grid-column: 5 / 9;
                  -ms-grid-row: 3;
                  grid-row: 3;
                  margin-top: -50%;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                  margin: -10vw -5vw 0;
                }
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  margin-top: 5vw;
                }
                ${(props) =>
                  props.theme.mediaQueries
                    .medium} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                  margin-top: -50%;
                }
                ${(props) =>
                  props.theme.mediaQueries.small} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                  margin-top: 5vw;
                }
              `}
            >
              <FadeInGroup>
                <Image
                  src={
                    preview
                      ? page.collage_image_three && page.collage_image_three.url
                      : undefined
                  }
                  fluid={
                    preview
                      ? undefined
                      : data.prismicCommunity.data.collage_image_three &&
                        data.prismicCommunity.data.collage_image_three.fluid
                  }
                  alt={page.collage_image_three && page.collage_image_three.alt}
                />
              </FadeInGroup>
            </div>
          </Section>
          <Section
            pb={3}
            css={`
              ${(props) => props.theme.mediaQueries.small} {
                padding-bottom: 0;
              }
            `}
          >
            <div
              css={`
                -ms-grid-column: 1;
                -ms-grid-column-span: 23;
                grid-column: 1 / span 12;
                -ms-grid-row: 1;
                grid-row: 1;
                margin-right: -5vw;
                margin-left: -5vw;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 15;
                  grid-column: 1 / 9;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                  margin-right: -10vw;
                  margin-left: -10vw;
                }
              `}
            >
              <Grid data={data} page={page} preview={preview} />
            </div>
          </Section>
          <FadeGroup>
            <SplitImage
              src={
                preview ? page.footer_image && page.footer_image.url : undefined
              }
              fluid={
                preview
                  ? undefined
                  : data.prismicCommunity.data.footer_image &&
                    data.prismicCommunity.data.footer_image.fluid
              }
              alt={page.footer_image && page.footer_image.alt}
              bg="brand.nickel"
              css={`
                ${(props) => props.theme.mediaQueries.small} {
                  padding-bottom: 0;
                }
              `}
            />
          </FadeGroup>
        </>
      );
    }}
  />
);

export default CommunityPage;
