import React, { useEffect, useState } from "react";
import { keyframes } from "styled-components";
import { Watch } from "scrollmonitor-react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { fluidType } from "utils";
import { H3, Image, Text } from "components";

const timing = 600;
const bezierCurve = "cubic-bezier(0.215, 0.61, 0.355, 1)";

const peekAnimation = keyframes`
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;
  }
`;

const Container = ({ show, body, heading, src, fluid, alt }) => (
  <div
    css={`
      position: relative;
      padding-bottom: 100%;
      cursor: pointer;
    `}
  >
    <div
      css={`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      `}
    >
      <Image
        src={src}
        fluid={fluid}
        alt={alt}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
    <div
      css={`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2.5vw;
        background-color: ${(props) => props.theme.colors.bg.wash};
        transition: opacity ${timing * 1 + "ms"} ${bezierCurve};
        // opacity: 0;

        &.peek {
          animation: ${peekAnimation} 1.66s ${bezierCurve};
        }

        &:hover {
          opacity: 0 !important;
          > div {
            opacity: 0;
            transform: translateY(-5%);
          }
        }
      `}
      className={show ? "peek" : ""}
    >
      <div
        css={`
          width: 100%;
          max-width: 768px;
          margin: 0 auto;
          text-align: center;
          transition: opacity ${timing + "ms"} ${bezierCurve},
            transform ${timing + "ms"} ${bezierCurve};
        `}
      >
        <H3 children={heading} mb="0.5em" color="brand.rose" />
        <Text children={body} fontSize={0.875} />
        <svg
          viewBox="0 0 42 42"
          css={`
            width: 1em;
            height: 1em;
            margin-top: 1em;
            ${fluidType(2)};
            vertical-align: top;
          `}
        >
          <path d="M42 20H22V0h-2v20H0v2h20v20h2V22h20z" fill="#7CA1B7" />
        </svg>
      </div>
    </div>
  </div>
);

function Grid({ isInViewport, data, page, preview }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (isInViewport && !show) {
      setShow(true);
    }
  }, [isInViewport]);

  return (
    <div
      css={`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 2fr 2px 1fr 2px 1fr;
        grid-template-columns: 2fr 2px 1fr 2px 1fr;
        -ms-grid-rows: 1fr 2px 1fr;
        grid-template-rows: 1fr 2px 1fr;
        > * {
          &:nth-child(1) {
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 1;
            -ms-grid-row-span: 3;
            grid-row: 1 / 4;
            height: calc(100vw / 2);
          }
          &:nth-child(2) {
            -ms-grid-column: 3;
            grid-column: 3;
            -ms-grid-row: 1;
            grid-row: 1;
          }
          &:nth-child(3) {
            -ms-grid-column: 5;
            grid-column: 5;
            -ms-grid-row: 1;
            grid-row: 1;
          }
          &:nth-child(4) {
            -ms-grid-column: 3;
            grid-column: 3;
            -ms-grid-row: 3;
            grid-row: 3;
          }
          &:nth-child(5) {
            -ms-grid-column: 5;
            grid-column: 5;
            -ms-grid-row: 3;
            grid-row: 3;
          }
        }
        @media screen and (max-width: 72em) {
          -ms-grid-columns: 1fr 2px 1fr;
          grid-template-columns: 1fr 2px 1fr;
          -ms-grid-rows: 1fr 2px 1fr 2px 1fr;
          grid-template-rows: 1fr 2px 1fr 2px 1fr;
          > * {
            height: calc(100vw / 2);
            &:nth-child(1) {
              -ms-grid-column: 1;
              -ms-grid-column-span: 3;
              grid-column: 1 / 4;
              -ms-grid-row: 1;
              grid-row: 1;
              padding-bottom: 50%;
            }
            &:nth-child(2) {
              -ms-grid-column: 1;
              grid-column: 1;
              -ms-grid-row: 3;
              grid-row: 3;
            }
            &:nth-child(3) {
              -ms-grid-column: 3;
              grid-column: 3;
              -ms-grid-row: 3;
              grid-row: 3;
            }
            &:nth-child(4) {
              -ms-grid-column: 1;
              grid-column: 1;
              -ms-grid-row: 5;
              grid-row: 5;
            }
            &:nth-child(5) {
              -ms-grid-column: 3;
              grid-column: 3;
              -ms-grid-row: 5;
              grid-row: 5;
            }
          }
        }
        ${(props) => props.theme.mediaQueries.small} {
          -ms-grid-columns: 1fr;
          grid-template-columns: 1fr;
          -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
          > * {
            margin-bottom: 2px;
            height: 100vw;
            &:nth-child(1) {
              -ms-grid-column: 1;
              grid-column: 1;
              -ms-grid-row: 1;
              grid-row: 1;
              height: 100vw;
              padding-bottom: 0;
            }
            &:nth-child(2) {
              -ms-grid-column: 1;
              grid-column: 1;
              -ms-grid-row: 2;
              grid-row: 2;
            }
            &:nth-child(3) {
              -ms-grid-column: 1;
              grid-column: 1;
              -ms-grid-row: 3;
              grid-row: 3;
            }
            &:nth-child(4) {
              -ms-grid-column: 1;
              grid-column: 1;
              -ms-grid-row: 4;
              grid-row: 4;
            }
            &:nth-child(5) {
              -ms-grid-column: 1;
              grid-column: 1;
              -ms-grid-row: 5;
              grid-row: 5;
            }
          }
        }
      `}
    >
      <Container
        heading={page.main_feature && PrismicRichText.asText(page.main_feature)}
        body={page.main_feature_subtext}
        src={
          preview
            ? page.main_feature_image && page.main_feature_image.url
            : undefined
        }
        fluid={
          preview
            ? undefined
            : data.prismicCommunity.data.main_feature_image &&
              data.prismicCommunity.data.main_feature_image.fluid
        }
        alt={page.main_feature_image && page.main_feature_image.alt}
        delay={0}
        show={show}
      />
      <Container
        heading={page.feature_one && PrismicRichText.asText(page.feature_one)}
        body={page.feature_one_subtext}
        src={
          preview
            ? page.feature_one_image && page.feature_one_image.url
            : undefined
        }
        fluid={
          preview
            ? undefined
            : data.prismicCommunity.data.feature_one_image &&
              data.prismicCommunity.data.feature_one_image.fluid
        }
        alt={page.feature_one_image && page.feature_one_image.alt}
        delay={300}
        show={show}
      />
      <Container
        heading={page.feature_two && PrismicRichText.asText(page.feature_two)}
        body={page.feature_two_subtext}
        src={
          preview
            ? page.feature_two_image && page.feature_two_image.url
            : undefined
        }
        fluid={
          preview
            ? undefined
            : data.prismicCommunity.data.feature_two_image &&
              data.prismicCommunity.data.feature_two_image.fluid
        }
        alt={page.feature_two_image && page.feature_two_image.alt}
        delay={200}
        show={show}
      />
      <Container
        heading={
          page.feature_three && PrismicRichText.asText(page.feature_three)
        }
        body={page.feature_three_subtext}
        src={
          preview
            ? page.feature_three_image && page.feature_three_image.url
            : undefined
        }
        fluid={
          preview
            ? undefined
            : data.prismicCommunity.data.feature_three_image &&
              data.prismicCommunity.data.feature_three_image.fluid
        }
        alt={page.feature_three_image && page.feature_three_image.alt}
        delay={400}
        show={show}
      />
      <Container
        heading={page.feature_four && PrismicRichText.asText(page.feature_four)}
        body={page.feature_four_subtext}
        src={
          preview
            ? page.feature_four_image && page.feature_four_image.url
            : undefined
        }
        fluid={
          preview
            ? undefined
            : data.prismicCommunity.data.feature_four_image &&
              data.prismicCommunity.data.feature_four_image.fluid
        }
        alt={page.feature_four_image && page.feature_four_image.alt}
        delay={100}
        show={show}
      />
    </div>
  );
}

export default Watch(Grid);
